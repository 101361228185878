import { compose } from 'redux';
import { locale } from 'bv-i18n';
import { createShortLink } from '../api';

import * as reducers from './reducers';

import * as templates from '../data/templates';

import mapNextOutcomesByDiff from '../helpers/map_next_outcomes_by_diff';
import { decode } from '../helpers/transform';

export const getSavedBets = () => (
  JSON.parse(
    localStorage.getItem('betCalculator.savedBets') || '[]',
  ).map(decode)
);

export const initialState = {
  formState: templates.defaultFormState,
  betType: templates.betTypeTemplate.id,
  calculator: templates.calculatorTemplate,
  displayedModal: null,
  parameters: templates.parametersTemplate,
  savedBets: getSavedBets(),
  encodedBetState: null,
};

export const ON_CREATE_BITLY_LINK = 'BET_CALCULATOR::CALCULATOR::ON_CREATE_BITLY_LINK';
export const ON_CLICK_CALCULATE = 'BET_CALCULATOR::CALCULATOR::ON_CLICK_CALCULATE';
export const ON_ACCUMULATOR_ROW_ACTION = 'BET_CALCULATOR::CALCULATOR::ON_ACCUMULATOR_ROW_ACTION';
export const ON_CHANGE_BET_TYPE = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_BET_TYPE';
export const ON_CHANGE_CALCULATOR_PARAMETER = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_CALCULATOR_PARAMETER';
export const ON_CHANGE_FORECAST_TYPE = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_FORECAST_TYPE';
export const ON_CHANGE_OUTCOME_PARAMETER = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_OUTCOME_PARAMETER';
export const ON_CHANGE_PARAMETER = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_PARAMETER';
export const ON_CHANGE_PARAMETER_SELECTIONS = 'BET_CALCULATOR::CALCULATOR::ON_CHANGE_PARAMETER_SELECTIONS';
export const ON_CALCULATE = 'BET_CALCULATOR::CALCULATOR::ON_CALCULATE';
export const TOGGLE_MODAL = 'BET_CALCULATOR::CALCULATOR::TOGGLE_MODAL';
export const RESTORE_BET_STATE = 'BET_CALCULATOR::CALCULATOR::RESTORE_BET_STATE';
export const DELETE_SAVED_BET = 'BET_CALCULATOR::CALCULATOR::DELETE_SAVED_BET';

export const onClickCalculate = () => ({
  type: ON_CLICK_CALCULATE,
});

export const onCreateBitlyLink = (link) => ({
  type: ON_CREATE_BITLY_LINK,
  payload: {
    link,
  },
});

export const onChangeBetType = (betType) => ({
  type: ON_CHANGE_BET_TYPE,
  payload: {
    betType,
  },
});

export const onChangeCalculatorParameter = (key, value) => ({
  type: ON_CHANGE_CALCULATOR_PARAMETER,
  payload: {
    key,
    value: value.nativeEvent // eslint-disable-line
      ? value.target.type === 'checkbox' ? value.target.checked : value.target.value
      : value,
  },
});

export const onAccumulatorRowAction = (action) => (dispatch) => {
  dispatch({
    type: ON_ACCUMULATOR_ROW_ACTION,
    payload: {
      shouldAdd: action === 'add',
    },
  });
  dispatch(onChangeCalculatorParameter('isShareCollapsed', true));
};

export const onChangeForecastType = (type) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_FORECAST_TYPE,
    payload: {
      type,
    },
  });
  dispatch(onChangeCalculatorParameter('isShareCollapsed', true));
};

export const onChangeParameter = (key, value) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_PARAMETER,
    payload: {
      key,
      value: value.nativeEvent // eslint-disable-line
        ? value.target.type === 'checkbox' ? value.target.checked : value.target.value
        : value,
    },
  });
  dispatch(onChangeCalculatorParameter('isShareCollapsed', true));
};

export const onChangeParameterSelections = (event) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_PARAMETER_SELECTIONS,
    payload: {
      value: event.target.value,
    },
  });
  dispatch(onChangeCalculatorParameter('isShareCollapsed', true));
};

export const onChangeOutcomeParameter = ({ outcomeId, parameter }, value) => (dispatch) => {
  dispatch({
    type: ON_CHANGE_OUTCOME_PARAMETER,
    payload: {
      outcomeId,
      parameter,
      value: value.nativeEvent // eslint-disable-line
        ? value.target.type === 'checkbox' ? value.target.checked : value.target.value
        : value,
    },
  });
  dispatch(onChangeCalculatorParameter('isShareCollapsed', true));
};

export const toggleModal = (displayedModal = null) => ({
  type: TOGGLE_MODAL,
  payload: {
    displayedModal,
  },
});

export const restoreBetState = (savedBet) => ({
  type: RESTORE_BET_STATE,
  payload: {
    savedBet,
  },
});

export const deleteSavedBet = (savedBet) => ({
  type: DELETE_SAVED_BET,
  payload: {
    savedBet,
  },
});

export const onCreateBitlyLinkThunk = ({ betType, encodedBetState }) => (dispatch) => {
  const onCreateBitlyLinkAction = compose(dispatch, onCreateBitlyLink);

  const { protocol, host } = window.location;
  const longUrl = `${protocol}//${host}/${locale()}/bet-calculator/${betType.id}?c=${encodedBetState}`;

  onCreateBitlyLinkAction({ loading: true });

  createShortLink(longUrl)
    .then(({ link }) => {
      onCreateBitlyLinkAction({
        link,
        encodedLink: encodeURIComponent(link),
      });
    });
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ON_CREATE_BITLY_LINK: {
      const { link } = action.payload;

      return {
        ...state,
        calculator: {
          ...state.calculator,
          link,
        },
      };
    }

    case TOGGLE_MODAL: {
      const { displayedModal } = action.payload;

      return {
        ...state,
        displayedModal,
      };
    }

    case ON_CHANGE_CALCULATOR_PARAMETER: {
      const { key, value } = action.payload;

      return {
        ...state,
        calculator: {
          ...state.calculator,
          parameters: {
            ...state.calculator.parameters,
            [key]: value,
          },
        },
      };
    }

    case ON_CLICK_CALCULATE: return reducers.onClickCalculate(state, action);

    case ON_CHANGE_FORECAST_TYPE: {
      const { type } = action.payload;
      const currentQuantity = (
        (state.parameters.type && state.parameters.type.quantity)
        || templates.defaultForecastType.quantity
      );
      const diff = type.quantity - currentQuantity;

      const nextOutcomes = mapNextOutcomesByDiff(
        state.parameters.outcomes,
        diff,
        templates.outcomeTemplate,
      );

      return {
        ...state,
        parameters: {
          ...state.parameters,
          type,
          outcomes: nextOutcomes,
        },
      };
    }

    case ON_CHANGE_BET_TYPE: return reducers.onChangeBetType(state, action);

    case ON_CHANGE_PARAMETER: {
      const { key, value } = action.payload;

      return {
        ...state,
        parameters: {
          ...state.parameters,
          [key]: value,
        },
      };
    }

    case ON_CHANGE_PARAMETER_SELECTIONS: {
      const { outcomes } = state.parameters;
      const next = action.payload.value;
      const current = outcomes.length;
      const diff = next - current;

      if (!diff) return state;

      const nextOutcomes = mapNextOutcomesByDiff(
        outcomes,
        diff,
        templates.outcomeTemplate,
      );

      return {
        ...state,
        parameters: {
          ...state.parameters,
          betType: action.payload.value === 1 ? 'single' : 'accumulator',
          outcomes: nextOutcomes,
        },
      };
    }

    case ON_CHANGE_OUTCOME_PARAMETER: {
      const { outcomeId, parameter, value } = action.payload;
      const { outcomes } = state.parameters;

      const index = outcomes.findIndex(
        (entry) => entry.id === outcomeId,
      );
      const outcome = outcomes[index];

      const result = {
        ...state,
        parameters: {
          ...state.parameters,
          outcomes: [
            ...outcomes.slice(0, index),
            {
              ...outcome,
              [parameter]: value.nativeEvent // eslint-disable-line
                ? value.target.type === 'checkbox' ? value.target.checked : value.target.value
                : value,
            },
            ...outcomes.slice(index + 1),
          ],
        },
      };

      return result;
    }

    case ON_ACCUMULATOR_ROW_ACTION: {
      const { outcomes } = state.parameters;
      const diff = action.payload.shouldAdd ? 1 : -1;

      const nextOutcomes = mapNextOutcomesByDiff(
        outcomes,
        diff,
        templates.outcomeTemplate,
      );

      return {
        ...state,
        formState: {
          ...state.formState,
          hasError: false,
        },
        parameters: {
          ...state.parameters,
          outcomes: nextOutcomes,
        },
      };
    }

    case RESTORE_BET_STATE: {
      const { savedBet: { betType, parameters, calculator } } = action.payload;

      const encodedBetState = btoa(JSON.stringify({ betType, parameters, calculator }));

      return {
        ...state,
        betType,
        parameters,
        calculator: {
          ...calculator,
          parameters: {
            ...calculator.parameters,
            isShareCollapsed: false,
          },
          link: '',
        },
        encodedBetState,
      };
    }

    case DELETE_SAVED_BET: {
      const { savedBet } = action.payload;
      const { savedBets } = state;

      const index = savedBets
        .findIndex((entry) => entry.calculator.id === savedBet.calculator.id);

      return {
        ...state,
        savedBets: savedBets
          .slice(0, index)
          .concat(
            savedBets.slice(index + 1),
          ),
      };
    }

    default:
      return state;
  }
};
